<template>
    <div class="gradient-background">
        <div class="detail-page">
            <h1 class="gradient-text">{{ detail.vod_name }}</h1>
            <div class="player-controls">
                <button @click="playPrevious" :disabled="currentEpisodeIndex === 0">上一集</button>
                <span>当前播放：{{ episodes[currentEpisodeIndex]?.name }}</span>
                <button @click="playNext" :disabled="currentEpisodeIndex === episodes.length - 1">下一集</button>
            </div>
            <iframe :src="playerUrl" width="100%" height="600px" frameborder="0" allowfullscreen></iframe>
            <div class="episode-list">
                <button
                    v-for="(episode, index) in episodes"
                    :key="index"
                    @click="playEpisode(index)"
                    :class="['episode-button', { 'active': index === currentEpisodeIndex }]"
                >
                    {{ episode.name }}
                </button>
            </div>
            <div class="vod-content" v-html="detail.vod_content"></div>
        </div>
        <div
            class="floating-buttons"
            @mousedown="startDrag"
            @mousemove="drag"
            @mouseup="endDrag"
            @mouseleave="endDrag"
            @touchstart="startDrag"
            @touchmove="drag"
            @touchend="endDrag"
        >
            <button @click="goBack">返回上一页</button>
            <button @click="goHome">返回首页</button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import { ElMessage } from 'element-plus'


const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

const route = useRoute()
const router = useRouter()
const detail = ref({})
const episodes = ref([])
const playerUrl = ref('')
const currentEpisodeIndex = ref(0)
let playerBaseUrl = ''

const fetchPlayerUrl = async () => {
    try {
        const response = await axios.get(`${apiBaseUrl}/getPlayer`)
        if (response.data.code === '200') {
            const { players, players_default } = response.data.data
            const playerMap = new Map(players.split(',').map(player => {
                const [url, apiTag] = player.split('|')
                return [apiTag, url]
            }))
            playerBaseUrl = playerMap.get(route.params.api_identifier) || players_default
        }
    } catch (error) {
        console.error('获取播放器地址时出错：', error)
    }
}

const fetchDetail = async () => {
    const vodId = route.params.id
    const apiTag = route.params.api_identifier
    try {
        const response = await axios.get(`${apiBaseUrl}/getDetail`, {
            params: { vodId, apiTag }
        })
        if (response.data.code === '200') {
            detail.value = response.data.data[0] || {}

            // 使用 $$ 分割不同播放类型
            const playSourcesArray = detail.value.vod_play_url.split('$$$')

            // 找到包含 .m3u8 的播放源
            const m3u8Source = playSourcesArray.find(source => source.includes('.m3u8'))

            // 如果找到，进行进一步处理
            if (m3u8Source) {
                const playUrls = m3u8Source.split('#')

                episodes.value = playUrls.map(url => {
                    const [name, link] = url.split('$')
                    return { name, url: link }
                })
                playEpisode(0)
            } else {
                ElMessage({
                    message: '未找到包含 .m3u8 的播放源',
                    type: 'warning',
                })
            }
        }
    } catch (error) {
        console.error('获取详情数据时出错：', error)
    }
}

const playEpisode = (index) => {
    currentEpisodeIndex.value = index
    playerUrl.value = `${playerBaseUrl}${episodes.value[index].url}`
}

const playPrevious = () => {
    if (currentEpisodeIndex.value > 0) {
        playEpisode(currentEpisodeIndex.value - 1)
    }
}

const playNext = () => {
    if (currentEpisodeIndex.value < episodes.value.length - 1) {
        playEpisode(currentEpisodeIndex.value + 1)
    }
}

const goBack = () => {
    router.back()
}

const goHome = () => {
    router.push('/')
}

const isDragging = ref(false)
const startX = ref(0)
const startY = ref(0)
const offsetX = ref(0)
const offsetY = ref(0)

const startDrag = (event) => {
    const isTouchEvent = event.type === 'touchstart'
    const clientX = isTouchEvent ? event.touches[0].clientX : event.clientX
    const clientY = isTouchEvent ? event.touches[0].clientY : event.clientY

    isDragging.value = true
    startX.value = clientX
    startY.value = clientY
    offsetX.value = event.target.parentElement.offsetLeft
    offsetY.value = event.target.parentElement.offsetTop
}

const drag = (event) => {
    if (isDragging.value) {
        const isTouchEvent = event.type === 'touchmove'
        const clientX = isTouchEvent ? event.touches[0].clientX : event.clientX
        const clientY = isTouchEvent ? event.touches[0].clientY : event.clientY

        const dx = clientX - startX.value
        const dy = clientY - startY.value

        const button = event.target.parentElement
        button.style.left = `${offsetX.value + dx}px`
        button.style.top = `${offsetY.value + dy}px`
    }
}

const endDrag = () => {
    isDragging.value = false
}

onMounted(async () => {
    await fetchPlayerUrl()
    fetchDetail()
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.gradient-background {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    background: linear-gradient(135deg, #ffccff, #ccffff, #cc99ff);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.detail-page {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.8);
}

.gradient-text {
    margin: 0;
    font-size: 24px;
    background: linear-gradient(135deg, #000000, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-tag {
    font-size: 16px;
    background: linear-gradient(135deg, #030303, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    margin: 10px auto 20px;
}

.player-controls {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.player-controls button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.player-controls button:disabled {
    background-color: #a9a9a9;
    cursor: not-allowed;
}

.episode-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

.episode-button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.episode-button.active {
    background-color: #0056b3;
}

.episode-button:hover:not(.active) {
    background-color: #0056b3;
}

.vod-content {
    margin-top: 20px;
    text-align: left;
    font-size: 14px;
    line-height: 1.6;
}

.floating-buttons {
    position: fixed;
    bottom: 50px; /* 确保在免责声明上方 */
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 9999; /* 设置较高的 z-index */
    cursor: move; /* 显示拖动图标 */
}

.floating-buttons button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.floating-buttons button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    iframe {
        height: 300px; /* 在移动端将播放器高度设为300px */
    }
}
</style>
